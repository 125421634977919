import 'url-polyfill';
import 'es6-promise/auto';

if (!String.prototype.normalize) {
  import(/* webpackChunkName: "unorm" */ 'unorm');
}

if (typeof Object.assign != 'function') {
  Object.assign = function (target, varArgs) {
    // .length of function is 2
    'use strict';
    if (target == null) {
      // TypeError if undefined or null
      throw new TypeError('Cannot convert undefined or null to object');
    }

    var to = Object(target);

    for (var index = 1; index < arguments.length; index++) {
      var nextSource = arguments[index];

      if (nextSource != null) {
        // Skip over if undefined or null
        for (var nextKey in nextSource) {
          // Avoid bugs when hasOwnProperty is shadowed
          if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
            to[nextKey] = nextSource[nextKey];
          }
        }
      }
    }
    return to;
  };
}

if (!String.prototype.startsWith) {
  String.prototype.startsWith = function (search, pos) {
    return this.substr(!pos || pos < 0 ? 0 : +pos, search.length) === search;
  };
}

export function attrValue(element: HTMLElement, attributeName: string): string {
  if (element.dataset) {
    return element.dataset[attributeName];
  }
  let t = attributeName.replace(/([A-Z])/g, ($1) => '-' + $1.toLowerCase());
  return element.getAttribute(`data-${t}`);
}

export function attrs(element: HTMLElement): Array<string> {
  if (element.dataset) {
    return Object.keys(element.dataset);
  }
  let length = element.attributes.length;
  let output = [];
  for (let i = 0; i < length; i++) {
    let attribute = element.attributes[i];
    if (attribute && attribute.name && /^data-\w[\w\-]*$/.test(attribute.name)) {
      let attrName = attribute.name;
      // Change to CamelCase
      output.push(attrName.substr(5).replace(/-./g, (f) => f.charAt(1).toUpperCase()));
    }
  }
  return output;
}

if (!String.prototype.endsWith) {
  String.prototype.endsWith = function (searchString, position) {
    var subjectString = this.toString();
    if (
      typeof position !== 'number' ||
      !isFinite(position) ||
      Math.floor(position) !== position ||
      position > subjectString.length
    ) {
      position = subjectString.length;
    }
    position -= searchString.length;
    var lastIndex = subjectString.lastIndexOf(searchString, position);
    return lastIndex !== -1 && lastIndex === position;
  };
}

if (!Array.prototype.findIndex) {
  Object.defineProperty(Array.prototype, 'findIndex', {
    value: function (predicate) {
      if (this == null) {
        throw new TypeError('"this" is null or not defined');
      }
      var o = Object(this);
      var len = o.length >>> 0;
      if (typeof predicate !== 'function') {
        throw new TypeError('predicate must be a function');
      }
      var thisArg = arguments[1];
      var k = 0;
      while (k < len) {
        var kValue = o[k];
        if (predicate.call(thisArg, kValue, k, o)) {
          return k;
        }
        k++;
      }
      return -1;
    },
    configurable: true,
    writable: true,
  });
}

if (!Array.prototype.find) {
  Object.defineProperty(Array.prototype, 'find', {
    value: function (predicate) {
      if (this == null) {
        throw TypeError('"this" is null or not defined');
      }

      var o = Object(this);
      var len = o.length >>> 0;
      if (typeof predicate !== 'function') {
        throw TypeError('predicate must be a function');
      }

      var thisArg = arguments[1];
      var k = 0;

      while (k < len) {
        var kValue = o[k];
        if (predicate.call(thisArg, kValue, k, o)) {
          return kValue;
        }
        k++;
      }

      return undefined;
    },
    configurable: true,
    writable: true,
  });
}

/*
Polyfill for KeyboardEvent constructor.

References:
https://cdn.jsdelivr.net/npm/events-polyfill@2.1.2/event-constructor-polyfill.js
https://stackoverflow.com/questions/45994059/cant-create-a-keyboardevent-for-testing-an-input-component-angular-within-int?rq=1
*/
export function CbKeyboardEvent(eventName, params?): any {
  try {
    return new KeyboardEvent(eventName, params);
  } catch (error) {
    params = params || {};
    var event: any = document.createEvent('KeyboardEvent');
    event.initKeyboardEvent(
      eventName,
      params.bubbles === void 0 ? false : params.bubbles,
      params.cancelable === void 0 ? false : params.cancelable,
      params.view === void 0 ? window : params.view,
      params.key === void 0 ? '' : params.key,
      params.location === void 0 ? 0 : params.location,
      (params.ctrlKey === true ? 'Control ' : '') +
        (params.altKey === true ? 'Alt ' : '') +
        (params.shiftKey === true ? 'Shift ' : '') +
        (params.metaKey === true ? 'Meta ' : ''),
      params.repeat === void 0 ? false : params.repeat,
      params.locale === void 0 ? navigator.language : params.locale
    );
    if (params.key) {
      Object.defineProperties(event, {which: {get: () => params.key}});
      Object.defineProperties(event, {keyCode: {get: () => params.key}});
    }
    return event;
  }
}
