export default class Ids {
  static readonly UTILITY_FRAME: string = 'cb-utility-frame';
  static readonly MASTER_FRAME: string = 'cb-master-frame';
  static readonly HOST_NAME: string = '__HOST__';
  static readonly CONTAINER: string = 'cb-container';
  static readonly CB_FRAME: string = 'cb-frame';
  static readonly CB_LOADER: string = 'cb-loader';
  static readonly CB_LOADER_HEADER: string = 'cb-loader-header';
  static readonly CB_HEADER_LOGO: string = 'cb-header-logo';
  static readonly CB_ERROR: string = 'cb-error';
  static readonly CB_LOADING_BAR: string = 'cb-loading-bar';
  static readonly CB_MODAL_CLOSE: string = 'cb-modal-close';
  static readonly CB_PLACE_HOLDER: string = 'cb-placeholder';
}
