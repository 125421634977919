export default {
  container: {
    position: 'fixed',
    right: '0',
    bottom: '0',
    left: '0',
    top: '0',
    'z-index': '2147483647',
    display: 'none',
    '-webkit-overflow-scrolling': 'touch',
    'overflow-y': 'hidden',
  },
  iframe: {
    width: '100%',
    height: '100%',
    zIndex: 999999,
    visibility: 'hidden',
    position: 'relative',
    border: '0',
  },
  iframe_hidden: {
    width: '0',
    height: '0',
    visibility: 'hidden',
  },
  loader_container: {
    zIndex: 99999,
    visibility: 'hidden',
    transition: 'all 0.5s ease',
    background: '#f4f5f9',
    position: 'absolute',
    left: '0',
    right: '0',
    boxShadow:
      '0 2px 9px 0 rgba(0, 0, 0, 0.1), 0 20px 30px 1px rgba(0, 0, 0, 0.15), 0 40px 40px 1px rgba(0, 0, 0, 0.15)',
  },
  loader_fullpage_checkout: {
    width: '100%',
    height: '100%',
    margin: '0',
    'background-color': '#f4f5f9',
  },
  loader_v4: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '0',
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: '#f4f5f9',
    opacity: '.75',
    visibility: 'visible',
  },

  loader_wrapper: {
    overflow: 'hidden',
  },
  loader_container_mobile: {
    height: '100%',
    width: '100%',
  },
  loader_container_web: {
    width: '400px',
    height: '480px',
    margin: '20px auto',
  },
  loader_header: {
    padding: '12px 40px',
    background: '#fff',
    boxShadow: '0 1px 2px 0 rgba(0,0,0,.1)',
    textAlign: 'center',
    minHeight: '64px',
    boxSizing: 'border-box',
  },
  loader_header_logo: {
    minHeight: '46px',
    display: 'flex',
    alignItems: 'center',
    marginTop: '1px',
  },
  loader_header_img: {
    maxHeight: '40px',
    maxWidth: '240px',
    verticalAlign: 'middle',
    width: 'auto',
    marginTop: '3px',
    marginBottom: '3px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  loading_bar: {
    height: '2px',
    background: '#48e79a',
    transitionDuration: '0.5s',
    transitionTimingFunction: 'ease-in-out',
    visibility: 'hidden',
  },
  loader_content: {
    padding: '24px 36px',
  },
  placeholder_sm: {
    height: '10px',
    width: '150px',
    background: 'rgb(238,238,238)',
    backgroundSize: '400px 104px',
    marginBottom: '10px',
  },
  placeholder_md: {
    height: '20px',
    width: '100px',
    background: 'rgb(238,238,238)',
    backgroundSize: '400px 104px',
    marginBottom: '10px',
  },
  placeholder_lg: {
    height: '40px',
    width: '200px',
    background: 'rgb(238,238,238)',
    backgroundSize: '400px 104px',
    marginBottom: '10px',
  },
  wavering: {
    background: [
      '#f6f7f8',
      '-webkit-gradient(linear, left top, right top, color-stop(8%, #eeeeee ), color-stop(18%, #dddddd ), color-stop(33%, #eeeeee ))',
      '-webkit-linear-gradient(left, #eeeeee 8%, #dddddd 18%, #eeeeee 33%)',
      'linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%)',
    ],
    backgroundSize: '800px 104px',
  },
  cb_error: {
    fontSize: '18px',
    lineHeight: '27px',
    color: '#F83030',
    textAlign: 'center',
    display: 'none',
  },
  loading_close: {
    position: 'absolute',
    background: '#393941',
    height: '24px',
    width: '24px',
    borderRadius: '50%',
    right: '-12px',
    top: '-12px',
    fontSize: '20px',
    color: 'rgba(255, 255, 255, 0.7)',
    textAlign: 'center',
    cursor: 'pointer',
    display: 'table',
    'font-weight': '400',
    lineHeight: '24px',
  },
};
